import dayjs from 'dayjs';
import { atom } from 'jotai';
import type { BoatCharterTypeDto, CartModelAddons, CurrencyCode, LanguageCode } from '~/api/client';
import { defaultBoatSearchOptions } from '~/api/config';

export const isBoatDetailsStoreInitializedAtom = atom(false);

export const isBookingBarExpandedAtom = atom(false);
export const isInquiryAtom = atom(false);
export const bannerErrorMessageAtom = atom('');

export const dateFromCacheAtom = atom('');

export const currencyAtom = atom<CurrencyCode>('USD');
// @ts-expect-error
export const languageAtom = atom<LanguageCode>('en');

// url things
export const selectedCharterTypeAtom = atom<BoatCharterTypeDto | null>(null);
export const charterTypeIdAtom = atom<number | null>((get) => {
  const charterType = get(selectedCharterTypeAtom);
  return charterType?.Id ?? null;
});
export const charterTypeLabelAtom = atom<string | null>(null);
export const selectedAdditionalItemsAtom = atom<number[]>([]);
export const selectedAddonsAtom = atom<CartModelAddons[]>([]);
export const availableFromAtom = atom<string>(dayjs().add(3, 'days').format('YYYY-MM-DD'));
export const passengerCountAtom = atom(defaultBoatSearchOptions.passengersCount);
export const startTimeAtom = atom<{
  label: string;
  value: string;
}>({
  label: '',
  value: '',
});

export const orderParamsAsUrlQueryAtom = atom((get) => {
  const currency = get(currencyAtom);
  const language = get(languageAtom);

  const charterTypeId = get(charterTypeIdAtom);
  const passengers = get(passengerCountAtom);
  const startTime = get(startTimeAtom);
  const additionalItems = get(selectedAdditionalItemsAtom);
  const addons = get(selectedAddonsAtom);
  const availableFrom = get(availableFromAtom);

  const charterTypeIdQuery = charterTypeId ? `charterTypeId=${charterTypeId}` : '';
  const passengersQuery = passengers ? `&passengers=${passengers}` : '';
  const startTimeQuery = startTime.value ? `&startTime=${startTime.value}` : '';
  const availableFromQuery = availableFrom ? `&availableFrom=${availableFrom}` : '';

  const addonsQuery = addons.length ? `&addons=${JSON.stringify(addons)}` : '';
  const additionalItemsQuery = additionalItems.length ? `&additionalItems=${additionalItems.join(',')}` : '';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const languageQuery = language !== 'en' ? `&lang=${language}` : '';
  const currencyQuery = currency !== 'USD' ? `&currency=${currency}` : '';

  return `?${charterTypeIdQuery}${passengersQuery}${availableFromQuery}${startTimeQuery}${addonsQuery}${additionalItemsQuery}${languageQuery}${currencyQuery}`;
});

export interface OrderParams {
  availableFrom?: string;
  passengers: string;
  timeFrom: string;
  additionalItems?: string;
  addons?: string;
  language?: any;
  currency?: any;
}

export const setOrderParamsFromUrlAtom = atom(null, (get, set, orderParams: OrderParams) => {
  const { availableFrom, passengers, timeFrom, additionalItems, addons, language, currency } = orderParams;

  set(startTimeAtom, {
    label: timeFrom,
    value: timeFrom,
  });

  if (availableFrom) {
    set(availableFromAtom, availableFrom);
  }

  if (passengers) {
    set(passengerCountAtom, parseInt(passengers));
  }

  if (addons) {
    set(selectedAddonsAtom, JSON.parse(String(addons)));
  }

  if (additionalItems) {
    set(
      selectedAdditionalItemsAtom,
      additionalItems.split(',').map((x) => parseInt(x))
    );
  }

  if (language) {
    set(languageAtom, language);
  }

  if (currency) {
    set(currencyAtom, currency);
  }
});
