import React, { useContext } from 'react';
import { AppContext } from '~/providers/AppProvider';
import { useRouter } from 'next/router';
import { supportedCurrencies, getCurrencyCharacter } from '~/utils/currencyUtils';
import { SingleSelect } from '@boatsetter-backup/js-component-lib';
import { useAtom } from 'jotai';
import { currencyAtom } from '~/src/features/pdp/jotaiStore';
import { CurrencyCode } from '~/api/client';

interface Option {
  value: string;
  label: string;
}

const CurrencySwitcher = () => {
  const router = useRouter();
  const appCtx = useContext(AppContext);

  const [currency, setCurrency] = useAtom(currencyAtom);

  const currenciesObj = [];
  supportedCurrencies.map((cur) => {
    currenciesObj.push({
      value: cur,
      label: `${cur.toUpperCase()} ${getCurrencyCharacter(cur)}`,
    });
  });

  const changeCurrency = (newCurrency: Option) => {
    setCurrency(CurrencyCode[newCurrency.value.toUpperCase()]);

    appCtx.actions.setCurrency(newCurrency.value);

    const newQuery = {
      ...router.query,
      currency: newCurrency.value,
    };

    if (appCtx.state.defaultCurrency === newCurrency.value) {
      delete newQuery.currency;
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <SingleSelect
      instanceId={'currency_switcher'}
      options={currenciesObj}
      onChange={changeCurrency}
      value={{
        value: currency,
        label: `${currency.toUpperCase()} ${getCurrencyCharacter(currency)}`,
      }}
      placeholder={''}
    />
  );
};

export default CurrencySwitcher;
